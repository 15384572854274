import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { GET_HRMS_LIST } from "../actionTypes";

const getHRMSList = (state = {}, action) => {
  switch (action.type) {
    case GET_HRMS_LIST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  form: formReducer,
  getHRMSList,
});

export default rootReducer;
