import { Fragment } from "react";
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import {Container, TextField } from "@material-ui/core";
import { handleSplCase } from "../constant";
import { Label, LoadingIcon } from "../CommonTemplate/CommonStyles";
import {  Row, Button } from "./styles";



const DisplayDetails = (props) => {
  const details = _get(props,"data",{});

  return (
    <Container>
      {
        _isEmpty(details) ? <LoadingIcon>Loading...</LoadingIcon> :
        <Fragment>
          {Object.entries(details).map(([label, data], index) => (
            <Row  key={index}>
              <Label>{label}</Label>
              <TextField variant="outlined" disabled={true} value={handleSplCase(data)} />
    
            </Row>
          ))}
          <Row>
            <Button onClick={() => {window.location = "/"}}>Ok</Button>
          </Row>
        </Fragment>
      }
     
    </Container>
  );
};

export default DisplayDetails;
