import React from "react";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import RadioButtonGroup from "@material-ui/core/RadioGroup";
import styled from "styled-components";

export const renderTextField = ({
  label,
  input,

  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    placeholder={label}
    variant="outlined"
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

export const Error = styled.span`
  color: #f44336;
  font-size: 12px;
  padding: 5px 20px;
`;

const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return <Error className="custom-field-error">{touched && error}</Error>;
  }
};

export const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <FormControl error={touched && error}>
    <Select variant="outlined" native {...input} {...custom}>
      {children}
    </Select>
    {renderFromHelper({ touched, error })}
  </FormControl>
);

export const renderMenu_ItemSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  typeOfSelectClass,
  ...custom
}) => (
  <FormControl error={touched && error ? true : false}>
    <Select
      variant="outlined"
      {...input}
      {...custom}
      MenuProps={{
        classes: { list: typeOfSelectClass ? typeOfSelectClass : "" },
        variant: "menu",
      }}
    >
      {children}
    </Select>
    {renderFromHelper({ touched, error })}
  </FormControl>
);

export const renderMenuItemSelectField = ({
  value,
  children,
  meta: { touched, error },
  input,
  custom,
}) => {
  return (
    <FormControl error={touched && error ? true : false}>
      <Select
        variant="outlined"
        {...input}
        value={value}
        displayEmpty={true}
        defaultValue=""
        name="company"
        style={value === "" ? { color: "grey" } : { color: "black" }}
        {...custom}
      >
        {children}
      </Select>
      {renderFromHelper({ touched, error })}
    </FormControl>
  );
};

export const renderCheckbox = ({ input, label }) => (
  <Checkbox
    label={label}
    checked={input.value ? true : false}
    onChange={input.onChange}
    color="default"
    size="small"
    inputProps={{ "aria-label": "checkbox with small size" }}
  />
);

export const renderRadioGroup = ({ input, ...rest }) => (
  <RadioButtonGroup
    {...input}
    {...rest}
    valueSelected={input.value}
    onChange={(event, value) => input.onChange(value)}
  />
);

export const renderTextAreaField = ({
  label,
  input,
  showStartAdorment,
  meta: { touched, invalid, error },
  rows,
  ...custom
}) => (
  <FormControl error={touched && error}>
    <TextareaAutosize
      placeholder={label}
      variant="outlined"
      rows={!isNaN(rows) ? rows : 3}
      {...input}
      {...custom}
    />
    {renderFromHelper({ touched, error })}
  </FormControl>
);
