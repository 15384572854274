
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { Container } from "@material-ui/core";
import { getHrmsDetailsListApi } from "../../actions";
import { COMPANY_NOT_LISTED } from "./constant";
import { FormDetails } from "./Form";

const Login = (props) => {
  const [companyList, setCompanyList] = useState([]);
  const HRMSList = _get(props.HRMSListData, "data.hrms", []);

  useEffect(() => {
    props.getHRMSList();
  }, []);


  const redirect = (url) => {
    if(!_isEmpty(url)){
      window.open(url, "_blank");
    }
    else{
      console.log("Data is empty.")
    }
    
  }

  const submit = (formValues) => {
    console.log("submit formValues = ", formValues);

    if (formValues.company === COMPANY_NOT_LISTED) {
      let redirectURL  = _get(formValues,"HRMS.redirectUrl","");
      redirect(redirectURL);
    }
    else {
      console.log("Submit successful")
    }
  };
  return (
    <Container maxWidth="sm">
      <FormDetails
        onSubmit={submit}
        HRMSListDetails={props.HRMSListData}
        companyListDetails={companyList}
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    HRMSListData: state.getHRMSList,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getHRMSList: () => dispatch(getHrmsDetailsListApi()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
