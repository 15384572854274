import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: #eef1f3;
  }
`;
export const Container = styled.div`
  height: auto;
  width: 50%;
  margin: 10px auto;
  box-sizing: border-box;
  min-width: 360px;

  @media (max-width: 768px) {
    width: 100%;
    border: none;
    min-width: 300px;
  }
`;

export const MainContainer = styled.main`
  min-height: 80vh;
  height: auto;
  width: 100%;
  position: relative;
`;


///////////////// Form Styling ///////////////////

export const Label = styled.label`
  text-align: left;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.36px;
  color: ${props => props.theme.palette.fontColors.secondary};
  text-transform: capitalize;
  opacity: 1;

  margin-bottom: 10px;
`;

export const LoadingIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: ${props => props.theme.palette.primary.main};
  margin: 20px 10px;
  height: auto;
`;
