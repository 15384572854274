import React from "react";
import _get from "lodash/get";
import { ThemeProvider } from "styled-components";
import Color from "color";

export const appTheme = (props) => {
  let mainColor = _get(props, "primaryColor", "#007B8C");
  return {
    palette: {
      repute: {
        main: "#007B8C",
      },
      primary: {
        main: mainColor,
        light: Color(mainColor).alpha(0.1).fade(0.3),
        opaque: Color(mainColor).alpha(0.4).opaquer(0.5),
        dark: Color(mainColor).alpha(0.3).darken(1).hex(),
        lightest: Color(mainColor).lighten(0.8).hex(),
        appBGColor: "#F8F8F8",
      },
      fontColors: {
        main: "#1D2F51",
        secondary: "#2F394A",
        tableText: "#2D3748",
        teritary: "#6F8198",
        quaternary: "#4A5568",
        quinary: "#6E6B7B",
      },
      colors: {
        white: "#FFFFFF",
        black: "#000000",
        gray: "#C0C6CC",
        silverBlue: "#E1E6EA",
        lightSkyBlue: "#F1F5F8",
        accordionBlue: "#F6F7FB",
        lightGreenBlue: "#56A5DE",
        orange: "#EB6F5C",
        blue: "#2F394A",
        classicBlue: "#1976D2",
        violet: "#9B55EC",
        lightgray: `#F0F1F2`,
      },
      borderColors: {
        main: "#E6E6E6",
        secondary: "#EBE9F1",
      },
      accentColors: {
        success: "#00BC50",
        error: "#FF6B6B",
        pending: "#FFA041",
        info: "#0050BC",
        disabled: "#80bcc5",
      },
      status: {
        total: {
          bg: "#E8FBFF",
          fg: "#186876",
        },
        completed: {
          bg: "#EEFFF2",
          fg: "#29A745",
        },
        denied: {
          bg: "#F7F2FF",
          fg: "#583D7F",
        },
        pending: {
          bg: "#FFF4EA",
          fg: "#FF9941",
        },
        initiated: {
          bg: "#E2EEFF",
          fg: "#0050BC",
        },
        expired: {
          bg: "#EDEDED",
          fg: "#ADADAD",
        },
        failed: {
          bg: "#FFEBE6",
          fg: "#E76F51",
        },
      },
    },
  };
};

const StyledComponentTheme = ({ children, merchantConfigData }) => (
  <ThemeProvider theme={appTheme(merchantConfigData)}>{children}</ThemeProvider>
);

export default StyledComponentTheme;
