export const OnlySpaceText = (value) => (value.trim() ? undefined : "Required");
export const required = (value) => (value ? undefined : "Required");

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;
export const mobile = (value) =>
  value && !/^[7-9]\d{9}$/i.test(value) ? "Invalid mobile number" : undefined;

export const requiredUser = (value) =>
  value ? undefined : "Please fill in your username";
export const requiredPass = (value) =>
  value ? undefined : "Please fill in your password";

export const pancard = (value) =>
  value && !/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/i.test(value)
    ? "Invalid pan id"
    : undefined;

export const indianMobile = (value) =>
  value && !/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/i.test(value)
    ? "Invalid Mobile Number"
    : undefined;
export const isValidDateCheck = (value) => (value ? undefined : "Invalid Date");
