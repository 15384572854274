import { Fragment, useState } from "react";
import { Field, reduxForm } from "redux-form";
import MenuItem from "@material-ui/core/MenuItem";
import _get from "lodash/get";
import {
  renderMenuItemSelectField,
  renderTextField,
} from "../../utils/formFields";
import { OnlySpaceText, pancard, required } from "../../validation";
import { FormField, Label, useStyles, SelectWrap, LoginBtn } from "./styles";
import { LoadingIcon } from "../CommonTemplate/CommonStyles";
import { COMPANY_NOT_LISTED } from "./constant";

const SignIn = (props) => {
  const classes = useStyles();
  const status = ""; //Used later
  const { handleSubmit, HRMSListDetails, companyListDetails } = props;
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedHRMS, setSelectedHRMS] = useState("");

  const companyList = _get(companyListDetails, "data", "");
  const companyStatus = _get(companyListDetails, "status", "");
  const hrmsList = _get(HRMSListDetails, "data.hrms", "");
  const hrmsStatus = _get(HRMSListDetails, "status", "");
  
  const handleCompanychange = (e) => {
    setSelectedCompany(e.target.value);
  };
  const handleHRMSChange = (e) => {
    setSelectedHRMS(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormField>
        <Label>Username</Label>
        <Field
          label="username"
          name="username"
          type="text"
          placeholder="Username"
          component={renderTextField}
          validate={[required, OnlySpaceText]}
        />
      </FormField>

      <FormField>
        <Label>Pan Card</Label>
        <Field
          name="pancard"
          type="text"
          placeholder="Pan Card"
          component={renderTextField}
          label="Pan Card"
          validate={[required, pancard]}
        />
      </FormField>

      <FormField>
        <Label>Company</Label>
        <SelectWrap>
          <Field
            name="company"
            value={selectedCompany}
            onChange={handleCompanychange}
            component={renderMenuItemSelectField}
            displayEmpty
            validate={[required]}
          >
            <MenuItem value="" disabled selected>
              Select company
            </MenuItem>

            {companyStatus === "loading" ? (
              <LoadingIcon>Loading..</LoadingIcon>
            ) : companyStatus === "success" ? (
              companyList.map((item, index) => (
                <MenuItem key={`row${index}`} value={item.name}>
                  {item.name}
                </MenuItem>
              ))
            ) : null}

            <MenuItem value={COMPANY_NOT_LISTED}>{COMPANY_NOT_LISTED}</MenuItem>
          </Field>
        </SelectWrap>
      </FormField>

      {selectedCompany === COMPANY_NOT_LISTED && (
        <FormField>
          <Label>HR management System</Label>
          <SelectWrap>
            <Field
              name="HRMS"
              value={selectedHRMS}
              component={renderMenuItemSelectField}
              placeholder="HR management System"
              validate={[required]}
              onChange={handleHRMSChange}
              displayEmpty
            >
              <MenuItem
                className={classes.placeholder}
                value=""
                disabled
                selected
              >
                Select HRMS
              </MenuItem>
              {hrmsStatus === "loading" ? (
                <LoadingIcon>Loading..</LoadingIcon>
              ) : hrmsStatus === "success" ? (
                hrmsList.map((item, index) => (
                  <MenuItem key={`${item.id}_${index}`} value={item}>
                    {item.name}
                  </MenuItem>
                ))
              ) : null}

              <MenuItem value="Others" disabled>Others</MenuItem>
            </Field>
          </SelectWrap>
        </FormField>
      )}

      <FormField>
        <LoginBtn type="submit">
          {status === "loading" ? <Fragment>Loading...</Fragment> : "Next"}
        </LoginBtn>
      </FormField>
    </form>
  );
};

export const FormDetails = reduxForm({
  form: "SignIn",
})(SignIn);
