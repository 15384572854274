import { useParams } from "react-router";
import DisplayDetails from "./DisplayDetails";
import { Container } from "./styles";
import _get from "lodash/get"
import { connect } from "react-redux";

const CandidateDetails = (props) => {
 const {details} = useParams();
  const candidateDetails = JSON.parse(
    decodeURIComponent(details));
    
  return (
    <Container>
      <DisplayDetails data={candidateDetails} />
    </Container>
  );
};

const mapPropsToState = (state) => ({})

const mapDispatchTiState = (dispatch) => ({});

export default connect(mapPropsToState, mapDispatchTiState)(CandidateDetails);

