import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const Section = styled.div`
  display: flex;
  justify-content: center;
`;

export const useStyles = makeStyles((theme) => ({
  placeholder: {},
}));
///////////////// Form Styling ///////////////////

export const FormField = styled.div`
  margin-bottom: 40px;

  .MuiTextField-root {
    width: 100%;

    border-radius: 7px;
    input {
      background-color:  ${props => props.theme.palette.colors.white};
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 28px;
  }
`;



export const Label = styled.div`
  text-align: left;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.36px;
  color: #2f394a;
  text-transform: capitalize;
  opacity: 1;

  margin-bottom: 10px;
`;
export const InputLabel = styled(Label)`
`
export const SelectWrap = styled.div`
  display: flex;
  width: 100%;
  //height: 35px;
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    //border: none;
  }
  .MuiSelect-selectMenu {
    background: #fff 0% 0% no-repeat padding-box;
    color: black;
    border-radius: 7px;
  }
  .MuiSelect-icon {
    color: black;
  }
  .MuiSelect-select:focus {
    background: #fff;
    border-radius: 7px;
  }
`;

export const LoginBtn = styled.button`
  background: ${(props) => props.theme.palette.primary.main} 0% 0% no-repeat
    padding-box;
  border-radius: 8px;
  width: 60%;
  border: none;
  text-align: center;
  font: Bold 16px/19px Lato;
  letter-spacing: 0.8px;
  color: ${(props) => props.theme.palette.colors.white};
  text-transform: capitalize;
  opacity: 1;
  padding: 12px;
  height: 45px;
  display: block;
  margin: 35px auto;
  cursor: pointer;
`;
