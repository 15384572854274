import { green } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  props: {
    MuiSelect: {
      MenuProps: {
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        style: {
          marginTop: "15px",
        },
      },
    },
  },
  typography: {
    fontFamily: [
      "Lato",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  overrides: {
    MuiInputBase: {
      root: {
        "&.Mui-disabled": {
          color: "#000",
          backgroundColor: "#ECECEC",
          borderRadius: "7px",
        },
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
        height: "unset",
        fontFamily: "Lato",
        "& textarea": {
          border: "1px solid #E6E6E6",
          borderRadius: "7px",
          padding: "10px 20px",
          fontSize: "13px",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        textAlign: "left",
        font: "Regular 18px/22px Lato",
        letterSpacing: "0.18px",
        fontSize: "13px",
        color: "#4A5569",
        height: "45px",
        "& $notchedOutline": {
          border: "1px solid #E6E6E6",
          borderRadius: "7px",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#4A90E2",
          "@media (hover: none)": {
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
        },
        "&$focused $notchedOutline": {
          borderColor: "#4A90E2",
          borderWidth: 1,
        },
        "&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
          {
            border: "none",
          },
      },
      input: {
        padding: "16px",
        borderRadius: "7px",
        fontWeight: "400",
      },
    },
    MuiPaper: {
      root: {
        "& > .custom-dayfield": {
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "300px",
          height: "230px",
          "& > .heading": {
            width: "100%",
            height: "30px",
          },
          "& > .content": {
            width: "30px",
            height: "30px",
            border: "1px solid #dcdcdc6b",
            display: "flex",
            justifyContent: "center",
            margin: "1px",
            fontSize: "12px",
          },
          "& > .content.Mui-selected": {
            color: "#FFFFFF",
            backgroundColor: "#6F8198",
          },
        },
        "& > .custom-monthfield": {
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "250px",
          height: "auto",
          "& > .heading": {
            width: "100%",
            height: "30px",
          },
          "& > .content": {
            width: "110px",
            height: "30px",
            border: "1px solid #dcdcdc6b",
            display: "flex",
            justifyContent: "center",
            margin: "1px",
            fontSize: "12px",
          },
          "& > .content.Mui-selected": {
            color: "#FFFFFF",
            backgroundColor: "#6F8198",
          },
        },
        "& > .custom-yearfield": {
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "500px",
          height: "auto",
          "& > .heading": {
            width: "100%",
            height: "30px",
          },
          "& > .content": {
            width: "50px",
            height: "30px",
            border: "1px solid #dcdcdc6b",
            display: "flex",
            justifyContent: "center",
            margin: "1px",
            fontSize: "12px",
          },
          "& > .content.Mui-selected": {
            color: "#FFFFFF",
            backgroundColor: "#6F8198",
          },
        },
      },
    },
    MuiSelect: {
      root: {
        textAlign: "left",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        fontSize: "14px",
        letterSpacing: "0px",
        color: "#6F8198",
        textTransform: "capitalize",
        padding: "15px 16px",
        fontWeight: 400,
        height: "45px",
        "&.Mui-disabled": {
          background: "#E6E6E6",
          borderRadius: "7px",
        },
        "&.MuiSelect-select:focus": {
          background: "transparent",
          borderRadius: "7px",
        },
      },
    },
    MuiMenu: {
      list: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #0000001A",
        borderRadius: "6px",
        padding: "10px",
      },
    },
    MuiListItem: {
      root: {
        "&.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover":
          {
            background: "#F0F5F8 0% 0% no-repeat padding-box",
            borderRadius: "4px",
          },
      },
    },
    MuiMenuItem: {
      root: {
        textAlign: "left",
        fontSize: "14px",
        letterSpacing: "0px",
        color: "#4A5568",
        textTransform: "capitalize",
      },
    },
    MuiTableSortLabel: {
      root: {
        "& $icon": {
          fontSize: "14px",
          opacity: 1,
          color: "#E5E6EE",
        },
      },
    },
  },
});
