import styled from "styled-components";
import { Label } from "../CommonTemplate/CommonStyles";

export const Container = styled.div`
  padding: 14px 22px;
  width: inherit;
  min-height: inherit;

  box-sizing: border-box;
  position: relative;
`;

export const Row = styled.div`
display:flex;
flex-direction: column;
 margin-bottom: 30px;
  ${Label}{
    margin-left: 12px;
  }
.MuiTextField-root {
  width: 100%;

  border-radius: 7px;
 
  input {
    padding: 10px 8px 10px 16px;
    background-color: transparent;
   
  }
}
@media only screen and (max-width: 768px) {
  width: 100%;
  margin-bottom: 18px;
}
`;

export const Button = styled.button`
  border: none;
  outline: none;
  border-radius: 4px;
  min-height: 40px;
  padding: 10px 14px;
  width: 40%;
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  line-height: 1.5;
  background-color: #1e7889;
  margin: 40px auto 10px auto;

  @media (max-height: 768px) {
    bottom: 20px;
  }
`;
