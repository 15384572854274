import { Container } from "./styles";

const Header = (props) => {

  return (
    <Container>
      <h1>Fintech </h1>
    </Container>
  );
};

export default Header;
