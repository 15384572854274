import styled from "styled-components";

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5b11b2;
  text-transform: uppercase;
  font-style: sans-serif;
`;
