import React from "react";
import { connect } from "react-redux";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import StyledComponentTheme from "../themes/basic-styles";
import { theme } from "../themes/common";

let AppLayout = (props) => {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StyledComponentTheme>{props.children}</StyledComponentTheme>
      </ThemeProvider>
    </StylesProvider>
  );
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(AppLayout);
