import { BrowserRouter as Router, Route, Routes ,Navigate} from "react-router-dom";
import CandidateDetails from "./apps/CadidateDetails";
import Header from "./apps/Header";
import Landing from "./apps/Landing";
import AppLayout from "./AppLayout";

import { Container, MainContainer, GlobalStyle } from "./apps/CommonTemplate/CommonStyles";


function App() {
  return (
    <Container>
      <AppLayout>
        <GlobalStyle />
        <Header />
        <MainContainer>
          <Router>
            <Routes>
            <Route exact path="/" element={<Navigate to={"/signin"} />} />
            <Route exact path="/signin" element={<Landing/>} />
            <Route
              path="candidate-details/:details"
              exact
              element={<CandidateDetails/>}
            />
            
            </Routes>
          </Router>
        </MainContainer>
      </AppLayout>
    </Container>
  );
}

export default App;
