import { GET_HRMS_LIST, GET_HRMS_DETAILS } from "../actionTypes";
import _get from "lodash/get";
import axios from "axios";

export const fetchAllHRMSDetails = (status, data) => ({
  type: GET_HRMS_LIST,
  payload: { status: status, data: data },
});

export function getHrmsDetailsListApi() {
  console.log("->", process.env.REACT_APP_API_URL);
  let url = `https://forms.qa.repute.net/hrms`;
  return (dispatch) => {
    dispatch(fetchAllHRMSDetails("loading"));
    axios
      .get(url)
      .then((response) => {
        console.log("hrms response = ", response);
        dispatch(fetchAllHRMSDetails("success", response.data.data));
      })
      .catch((error) => {
        let errMsg = _get(
          error,
          "response.data.error_data",
          "Something went wrong! Please try again."
        );
        console.log("hrms response error= ", errMsg);
        dispatch(fetchAllHRMSDetails("error", errMsg));
      });
  };
}
